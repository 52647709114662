@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.category-section {
  margin-bottom: $spacing-unit * 4;

  h2 {
    font-family: $font-secondary;
    color: $dark-purple;
    font-size: 1.8rem;
    margin-bottom: $spacing-unit * 2;
    border-bottom: 2px solid $dominant-purple;
    padding-bottom: $spacing-unit / 2;
  }

  .row {
    gap: $spacing-unit * 2;
  }

  .col {
    margin-bottom: $spacing-unit * 2;

    .content-card {
      @include box-shadow(1);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }
    }
  }
}
