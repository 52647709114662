@import '../styles/_variables';
@import '../styles/_mixins';
@import '~bootstrap/scss/bootstrap';

.customer-support {
  @include container;
  padding: $spacing-unit * 3;
  background-color: $very-light-gray;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
  
  h1 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit * 2;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  .nav-tabs {
    justify-content: center;
    border-bottom: 2px solid $light-gray !important;
    
    .nav-item {
      margin-bottom: -2px !important;
      &:hover {
        transform: scale(1.05);
      }

      .nav-link {
        color: $dark-purple !important;
        background-color: $very-light-gray !important;
        border-radius: 0.5rem 0.5rem 0 0 !important;
        margin: 0 0.25rem !important;
        transition: background-color 0.3s ease, color 0.3s ease !important;
        padding: $spacing-unit !important;
        font-weight: bold !important;
        &:hover {
          color: $dominant-purple !important;
          background-color: lighten($dark-purple, 40%) !important;
        }
        &.active {
          color: $white !important;
          background-color: $dominant-purple !important;
          border: none !important;
        }
      }
    }
  }

  .tab-content {
    padding: $spacing-unit * 3;
    background-color: white;
    border-radius: 0 0 0.5rem 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}