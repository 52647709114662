@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.custom-navbar {
  background-color: $cream-color;
  border-bottom: 1px solid $light-gray;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.75rem 1rem;

  .navbar-brand {
    display: flex;
    align-items: center;

    .navbar-logo {
      height: 75px; // Adjust height as needed
      width: auto;
      color: $dark-purple; // Use `color` for SVG
    }
  }

  .navbar-icons-container {
    display: flex;
    align-items: center;
    gap: 1rem; // Adjust spacing between icons

    .nav-link {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 1.2rem; // Adjust the font size if needed

      .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: $dominant-purple;
        color: white;
        font-size: 0.7rem; // Adjust font size for the badge
        padding: 0.2rem 0.4rem; // Adjust padding for the badge
        border-radius: 50%; // Make the badge circular
      }
    }
  }

  .nav-link {
    font-family: $font-navigation;
    color: $dark-purple;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    transition: color 0.3s, text-shadow 0.3s;

    &:hover {
      color: $dominant-purple;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }

  .navbar-toggler {
    border-color: $dark-purple;

    .navbar-toggler-icon {
      background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="rgba(93, 68, 95, 0.7)" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"/></svg>');
    }
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .navbar-user,
    .navbar-auth-links {
      display: flex;
      align-items: center;

      span {
        font-weight: bold;
        margin-right: 1rem;
      }

      .nav-link {
        font-family: $font-navigation;
        color: $dark-purple;

        &:hover {
          color: $dominant-purple;
        }
      }
    }
  }

  .navbar-search-container {
    .navbar-search {
      display: flex;
      align-items: center;

      .form-control {
        border-radius: 20px 0 0 20px;
        border: 1px solid $light-gray;
        padding: 0.5rem 1rem;
        width: 400px; // Adjust width as necessary

        @include transition(border-color, 0.3s);

        &:focus {
          border-color: $dark-purple;
        }
      }

      .btn {
        border-radius: 0 20px 20px 0;
        background-color: $dominant-purple;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.375rem 0.75rem;

        i {
          margin: 0;
        }

        &:hover {
          background-color: darken($dominant-purple, 10%);
        }
      }
    }
  }

  .nav-tabs {
    border-bottom: 1px solid $light-gray;
    margin-bottom: 0.5rem; // Reduced margin-bottom

    .nav-item {
      .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        padding: 0.5rem 1rem;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover {
          border-color: $light-gray $light-gray transparent;
          background-color: $very-light-gray;
        }
      }

      .nav-link.active {
        color: $dark-purple;
        background-color: $light-gray;
        border-color: $light-gray $light-gray transparent;
      }
    }
  }

  @media (max-width: 992px) {
    .navbar-top {
      flex-direction: column;
      align-items: center;

      .navbar-user,
      .navbar-auth-links {
        justify-content: center;
        width: 100%;
        margin-bottom: 0.5rem;
      }

      .navbar-icons-container {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 0.5rem; // Reduced margin-bottom

        .nav-link {
          margin: 0 0.5rem;
        }
      }
    }

    .navbar-search-container {
      width: 100%;
      margin-bottom: 0.5rem; // Reduced margin-bottom

      .navbar-search {
        width: 100%;

        .form-control {
          width: calc(100% - 50px); // Adjust based on the button width
        }
      }
    }
  }

  @media (max-width: 768px) {
    .navbar-top {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .navbar-user,
      .navbar-auth-links {
        flex-direction: column;
        align-items: center;

        span {
          margin-bottom: 0.5rem;
        }

        .nav-link {
          margin-bottom: 0.5rem;
        }
      }

      .navbar-icons-container {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 0.5rem; // Reduced margin-bottom

        .nav-link {
          margin: 0 0.5rem;
        }
      }
    }

    .navbar-search-container {
      width: 100%;
      margin-bottom: 0.5rem; // Reduced margin-bottom

      .navbar-search {
        width: 100%;

        .form-control {
          width: 100%;
        }
      }
    }

    .nav-tabs {
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.5rem; // Reduced margin-bottom

      .nav-item {
        width: 100%;
        text-align: center;

        .nav-link {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}