@import '../styles/variables';
@import '../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.product-detail-page {
  max-width: 1200px; // Constrain the page width
  margin: 0 auto; // Center the page
  padding: $spacing-unit;
  background-color: white;

  .product-main-section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-unit;

    .product-image-gallery {
      flex: 1 1 50%;
      padding: $spacing-unit;

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }

    .product-info {
      flex: 1 1 50%;
      padding: $spacing-unit;

      h1 {
        font-family: $font-primary;
        color: $dark-purple;
        margin-bottom: $spacing-unit-small;
        font-size: clamp(1.5rem, 2.5vw, 2.5rem); // Responsive scaling
      }

      .product-price {
        font-size: clamp(1.25rem, 2vw, 1.5rem); // Responsive scaling
        color: $dominant-purple;
        margin-bottom: $spacing-unit;
      }

      .reviews-summary {
        margin-bottom: $spacing-unit;
        font-size: 1em;
        color: $slate-gray;

        .star-icon {
          color: $dominant-purple;
        }
      }

      .product-buttons {
        display: flex;
        gap: $spacing-unit-small;
        margin-bottom: $spacing-unit;

        button {
          flex: 1;
          padding: $spacing-unit-small;
          font-family: $font-action;
          font-size: clamp(0.875rem, 1.5vw, 1rem); // Responsive scaling
          background-color: $dominant-purple;
          color: white;
          border: none;
          border-radius: 0.25rem;
          cursor: pointer;
          &:hover {
            background-color: darken($dominant-purple, 10%);
          }
        }
      }

      .product-tabs {
        margin-bottom: $spacing-unit;

        .nav-tabs {
          margin-bottom: $spacing-unit-small;
        }

        .tab-content {
          .tab-pane {
            padding: $spacing-unit;
            background-color: $cream-color;
            border: 1px solid $light-gray;
            border-radius: 0.25rem;

            p,
            li {
              font-family: $font-secondary;
              color: $dark-purple;
              font-size: clamp(0.875rem, 1.25vw, 1rem); // Responsive scaling
              line-height: 1.6;
            }

            ul {
              padding-left: 1.5rem;
            }

            h3 {
              font-family: $font-primary;
              color: $dominant-purple;
              margin-bottom: $spacing-unit-small;
            }

            .product-specs div,
            .product-reviews div {
              margin-bottom: $spacing-unit-small;
            }

            .product-reviews h3 {
              margin-bottom: $spacing-unit-small;
            }
          }
        }
      }

      .product-description,
      .product-specs,
      .product-reviews {
        margin-bottom: $spacing-unit;
      }
    }
  }

  .related-products {
    margin-top: $spacing-unit;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; // Ensure it doesn't exceed the page width

    h2 {
      font-family: $font-primary;
      color: $dark-purple;
      margin-bottom: $spacing-unit;
      font-size: clamp(1.5rem, 2.5vw, 2rem); // Responsive scaling
    }

    .related-products-carousel {
      width: 100%;
      max-width: 1000px; // Constrain the carousel width
      display: flex;
      overflow-x: auto; // Enable horizontal scrolling
      gap: 20px;
      padding: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .related-product-item {
        flex: 0 0 200px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          border-radius: 4px 4px 0 0;
        }

        h3 {
          font-size: clamp(1rem, 1.5vw, 1.125rem); // Responsive scaling
          margin: 10px 0;
          color: $dark-purple;
          font-family: $font-secondary;
        }

        p {
          font-size: clamp(0.875rem, 1.25vw, 1rem); // Responsive scaling
          color: #333;
        }

        &:hover {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
          transform: translateY(-2px);
          transition: all 0.3s ease;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .product-main-section {
      flex-direction: column;
    }

    .product-image-gallery,
    .product-info {
      flex: 1 1 100%;
      padding: $spacing-unit-small;
    }

    .related-products-carousel {
      gap: 10px;
    }
  }

  @media (max-width: 576px) {
    .product-buttons button {
      font-size: 14px;
      padding: $spacing-unit-small; // Reuse an existing variable instead of $spacing-unit-tiny
    }
  
    .related-product-item {
      flex: 0 0 150px; // Adjust for smaller screens
    }
  }
}