@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.product-highlights-container {
  display: flex; // equivalent to flex
  overflow-x: auto; // equivalent to overflow-x-auto
  gap: 1rem; // equivalent to space-x-4
  padding: 1rem; // equivalent to p-4

  .product-card {
    border: none; // equivalent to border-none
    border-radius: 0.5rem; // equivalent to rounded-lg
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // equivalent to shadow-md
    flex: none; // equivalent to flex-none
    width: 16rem; // equivalent to w-64
    height: auto;

    .product-image {
      border-top-left-radius: 0.5rem; // equivalent to rounded-t-lg
      border-top-right-radius: 0.5rem; // equivalent to rounded-t-lg
      max-height: 180px;
      object-fit: cover;
    }

    .card-body {
      display: flex; // equivalent to flex
      flex-direction: column; // equivalent to flex-col
      justify-content: space-between; // equivalent to justify-between
      padding: 1rem; // equivalent to p-4
    }

    .card-title {
      color: $dark-purple; // equivalent to text-dark-purple
      margin-bottom: 0.5rem; // equivalent to mb-2
      font-family: $font-secondary;
    }

    .card-text {
      color: $dark-purple; // equivalent to text-dark-purple
      margin-bottom: 1rem; // equivalent to mb-4
    }

    .btn {
      background-color: $dominant-purple; // equivalent to bg-dominant-purple
      color: white; // equivalent to text-white
      padding: 0.5rem 1rem; // equivalent to py-2 px-4
      border-radius: 0.25rem; // equivalent to rounded
      margin-top: auto; // equivalent to mt-auto
      &:hover {
        background-color: $dark-purple; // equivalent to bg-dark-purple
      }
    }
  }
}