@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.promotion-slider {
  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5); // equivalent to bg-opacity-50 bg-black
    padding: 1rem; // equivalent to p-4
    border-radius: 0.5rem; // equivalent to rounded

    h3 {
      color: $cream-color; // equivalent to text-cream-color
      font-family: $font-primary;
    }

    .btn {
      background-color: $dominant-purple; // equivalent to bg-dominant-purple
      color: white; // equivalent to text-white
      padding: 0.5rem 1rem; // equivalent to py-2 px-4
      border-radius: 0.25rem; // equivalent to rounded
      &:hover {
        background-color: $dark-purple; // equivalent to bg-dark-purple
      }
    }
  }
}