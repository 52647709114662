@import '../../styles/_variables';
@import '../../styles/_mixins';

.user-settings {
  flex: 1;
  min-width: 300px;
  padding: $spacing-unit * 2;
  background-color: #f9f9f9;
  border: 1px solid $light-gray;
  border-radius: 0.25rem;
  @include box-shadow(1);

  h2 {
    font-size: 1.5em;
    margin-bottom: $spacing-unit;
    color: $dark-purple;
    font-family: $font-primary;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: $spacing-unit / 2;
      font-weight: bold;
      font-family: $font-paragraph;
    }

    input {
      padding: $spacing-unit / 2;
      border: 1px solid $light-gray;
      border-radius: 0.25rem;
      margin-bottom: $spacing-unit;
      font-family: $font-forms;

      &:focus {
        border-color: $light-purple;
        outline: none;
      }
    }

    button {
      align-self: flex-end;
      @include button-styles($dominant-purple, white);
    }
  }
}