@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.search-component {
    margin-bottom: $spacing-unit * 2;
    text-align: center;
  
    .form-control {
      border: 1px solid lighten($dominant-purple, 20%);
      border-radius: 0.25rem 0 0 0.25rem;
      font-family: $font-forms;
    }
  
    .btn {
      @include button-styles($dominant-purple, white);
      border-radius: 0 0.25rem 0.25rem 0;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }
  }