@import '../styles/variables';
@import '../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.content-hub-page {
  background-color: $very-light-gray;
  padding: $spacing-unit * 3;
  @include container;

  // Hero Section
  .hero-section {
    text-align: center;
    margin-bottom: $spacing-unit * 4;
    background-color: $soft-yellow;
    padding: $spacing-unit * 2;
    border-radius: 0.25rem;
    @include box-shadow(2);

    .content-hub-title {
      font-family: $font-primary;
      color: $charcoal;
      @include responsive-font-size(2rem, 2.5rem, 3rem);
      margin-bottom: $spacing-unit * 2;
    }

    .search-bar {
      max-width: 600px;
      margin: 0 auto;
      @include box-shadow(1);
    }
  }

  // Featured Section
  .featured-section {
    margin-bottom: $spacing-unit * 4;

    .featured-item {
      background-color: $cream-color;
      padding: $spacing-unit * 2;
      border-radius: 0.25rem;
      margin-bottom: $spacing-unit * 2;
      @include box-shadow(3);

      .featured-title {
        font-family: $font-secondary;
        color: $dominant-purple;
        @include font-size(1.5rem);
      }

      .featured-description {
        color: $charcoal;
        @include text-truncate;
      }
    }
  }

  // Category Section
  .category-section {
    margin-bottom: $spacing-unit * 4;

    .category-header {
      font-family: $font-secondary;
      color: $dark-purple;
      @include font-size(1.5rem);
      margin-bottom: $spacing-unit;
    }

    .category-content {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-unit * 2;
      
      .category-item {
        flex: 1 1 calc(33.333% - $spacing-unit * 2);
        background-color: white;
        padding: $spacing-unit;
        border-radius: 0.25rem;
        @include box-shadow(1);
        margin-bottom: $spacing-unit;

        .category-title {
          font-family: $font-primary;
          color: $teal;
          @include font-size(1.2rem);
        }

        .category-description {
          color: $slate-gray;
          @include font-size(1rem);
          margin-top: $spacing-unit / 2;
        }
      }
    }
  }

  // Sidebar
  .sidebar {
    padding: $spacing-unit * 2;
    background-color: $very-light-gray;
    border-radius: 0.25rem;
    @include box-shadow(1);

    .sidebar-title {
      font-family: $font-navigation;
      color: $deep-blue;
      @include font-size(1.25rem);
      margin-bottom: $spacing-unit;
    }

    .sidebar-item {
      font-family: $font-primary;
      color: $dark-purple;
      margin-bottom: $spacing-unit;
      cursor: pointer;
      @include transition(color, 0.3s);

      &:hover {
        color: $rich-gold;
      }
    }
  }

  // Spotlight Section
  .spotlight-section {
    margin-top: $spacing-unit * 4;
    padding: $spacing-unit * 2;
    background-color: $dominant-purple;
    color: $cream-color;
    border-radius: 0.25rem;
    text-align: center;
    @include box-shadow(2);

    .spotlight-title {
      font-family: $font-special;
      @include font-size(1.8rem);
      margin-bottom: $spacing-unit * 2;
    }

    .spotlight-description {
      font-family: $font-primary;
      @include font-size(1rem);
    }
  }

  // Footer Navigation
  .footer-navigation {
    margin-top: $spacing-unit * 4;
    padding: $spacing-unit * 3;
    background-color: $charcoal;
    color: white;
    border-radius: 0.25rem;

    .footer-link {
      color: $light-gray;
      font-size: 0.9rem;
      display: inline-block;
      margin: 0 $spacing-unit / 2;
      @include transition(color);

      &:hover {
        color: $olive-green;
      }
    }
  }

  // Responsive Adjustments
  @include respond-to('small') {
    .content-hub-title {
      font-size: 1.8rem;
    }

    .category-item {
      flex: 1 1 100%;
    }
  }

  @include respond-to('medium') {
    .content-hub-title {
      font-size: 2.2rem;
    }

    .category-item {
      flex: 1 1 50%;
    }
  }

  @include respond-to('large') {
    .content-hub-title {
      font-size: 2.8rem;
    }

    .category-item {
      flex: 1 1 calc(33.333% - $spacing-unit * 2);
    }
  }
}
