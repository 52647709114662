@import '../../styles/_variables';
@import '../../styles/_mixins';
@import '~bootstrap/scss/bootstrap';

.payment-status-notification {
  @include container;
  padding: $spacing-unit;
  border-radius: 0.5rem;
  margin-bottom: $spacing-unit;
  text-align: center;
  font-weight: bold;
  font-family: $font-forms;

  // Base styling for all notifications
  &.processing,
  &.succeeded,
  &.failed {
    @include box-shadow(1);
    @include transition(background-color 0.3s, color 0.3s);
  }

  // Processing state
  &.processing {
    background-color: $soft-yellow;
    color: $charcoal;
    font-size: 1rem;
  }

  // Success state
  &.succeeded {
    background-color: $light-purple;
    color: $very-light-gray;
    font-size: 1.1rem;
    @include flex-center;
  }

  // Failure/Error state
  &.failed {
    background-color: $cream-color;
    color: $dark-purple;

    // Retry button styling
    .retry-button {
      @include button-styles($dominant-purple, $very-light-gray);
      margin-top: $spacing-unit-small;
      font-family: $font-action;
    }
  }

  // Styling for inner text
  p {
    margin: 0;
    font-size: 1rem;
  }
}