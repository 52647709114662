@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.product-image-gallery {
  .main-image {
    cursor: pointer;
    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .thumbnail-images {
    display: flex;
    overflow-x: auto;  // Enable horizontal scrolling
    gap: $spacing-unit-small;
    margin-top: $spacing-unit;
    padding: $spacing-unit-small; // Add padding to make scrolling smoother

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      cursor: pointer;
      border: 1px solid $light-gray;
      border-radius: 0.25rem;
      transition: border-color 0.2s ease-in-out;

      &.active,
      &:hover {
        border-color: $dominant-purple;
      }
    }
  }

  @include respond-to('small') {
    .thumbnail-images {
      gap: $spacing-unit-small;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}