@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.filter-sidebar {
  background-color: white; /* Use white */
  padding: $spacing-unit;
  border-right: 1px solid $light-gray;
  
  @include respond-to('medium') {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: $spacing-unit; /* Adjust based on your header height */
  }

  .collapse-button {
    display: block;
    margin-bottom: $spacing-unit;
    background-color: $dominant-purple;
    color: white;
    border: none;
    &:hover {
      background-color: darken($dominant-purple, 10%);
    }
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: $spacing-unit;
    font-family: $font-primary;
    color: $dark-purple;
  }

  .filter-group {
    margin-bottom: $spacing-unit;

    .form-label {
      font-size: 1em;
      margin-bottom: 0.5rem;
      font-family: $font-secondary;
      color: $dark-purple;
    }

    .form-control, .form-select {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid $light-gray;
      border-radius: 0.25rem;
      font-family: $font-forms;
    }

    .form-range {
      width: 100%;
      margin-top: 0.5rem;
    }

    span {
      display: block;
      margin-top: 0.5rem;
      font-family: $font-secondary;
    }

    button[type="submit"] {
      display: block;
      width: 100%;
      padding: 0.5rem;
      background-color: $dominant-purple;
      color: white;
      border: none;
      border-radius: 0.25rem;
      font-family: $font-action;
      cursor: pointer;
      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }
  }

  @include respond-to('small') {
    padding: $spacing-unit-small;
    .collapse-button, .form-control, .form-select, button[type="submit"] {
      width: 100%;
      margin-bottom: $spacing-unit-small;
    }
    position: static;
  }
}