@import '../styles/variables';
@import '../styles/mixins';
@import '~bootstrap/scss/bootstrap';
// @import '../styles/tailwind.css';  // Remove this line

.homepage {
  .section {
    padding: 2rem 0;
    &.bg-light {
      background-color: $light-gray;
    }
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }

  .section-title {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .hero-slider {
    .carousel-item {
      img {
        height: auto;
        max-height: 60vh;
        object-fit: cover;
      }
    }
    .carousel-caption {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 1rem;
      border-radius: 0.5rem;
      h3 {
        color: $cream-color;
        font-family: $font-primary;
      }
      .btn {
        @include button-styles($dominant-purple, white);
      }
    }
  }

  .product-highlights {
    .card {
      border: none;
      border-radius: 0.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 1.5rem;
      .card-img-top {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        height: 200px;
        object-fit: cover;
      }
      .card-title,
      .card-text {
        font-family: $font-secondary;
        color: $dark-purple;
      }
      .btn {
        @include button-styles($dominant-purple, white);
        &:hover {
          background-color: darken($dominant-purple, 10%);
        }
      }
    }
  }

  .testimonials {
    .card {
      border: none;
      border-radius: 0.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      margin-bottom: 1.5rem;
      .card-title {
        font-family: $font-secondary;
        color: $dark-purple;
      }
      .card-text {
        color: $dark-purple;
      }
      .card-footer {
        background-color: transparent;
        border-top: none;
        font-family: $font-paragraph;
        color: $dark-purple;
      }
    }
  }

  .newsletter-signup {
    background-color: $light-gray;
    text-align: center;
    padding: 2rem 0;
    h3 {
      font-family: $font-primary;
      color: $dark-purple;
      margin-bottom: $spacing-unit * 2;
    }
    .form-group {
      display: flex;
      justify-content: center;
      .form-control {
        max-width: 400px;
        margin-right: $spacing-unit;
        @include transition(border-color, 0.3s);
        &:focus {
          border-color: $dark-purple;
        }
      }
    }
    .btn {
      @include button-styles($dominant-purple, white);
      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }
    p {
      font-family: $font-paragraph;
      color: $dark-purple;
      margin-top: $spacing-unit;
    }
  }

  .customer-support-preview {
    text-align: center;
    padding: 2rem 0;
    background-color: $light-gray;

    h3 {
      font-family: $font-primary;
      color: $dark-purple;
      margin-bottom: 1.5rem;
    }

    p {
      font-family: $font-paragraph;
      color: $dark-purple;
      margin-bottom: 1.5rem;
    }

    .btn {
      @include button-styles($dominant-purple, white);
      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }
  }
}