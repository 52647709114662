// Flexbox Centering
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // Responsive Media Queries
  @mixin respond-to($breakpoint) {
    @if $breakpoint == 'small' {
      @media (max-width: 600px) { @content; }
    } @else if $breakpoint == 'medium' {
      @media (max-width: 900px) { @content; }
    } @else if $breakpoint == 'large' {
      @media (max-width: 1200px) { @content; }
    }
  }
  
  // Text Truncation
  @mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  // Box Shadows
  @mixin box-shadow($level: 1) {
    @if $level == 1 {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    } @else if $level == 2 {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    } @else if $level == 3 {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
  }
  
  // Clearfix
  @mixin clearfix {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
  
  // Transition
  @mixin transition($property: all, $duration: 0.3s, $timing: ease-in-out) {
    transition: $property $duration $timing;
  }
  
  // Container
  @mixin container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  // Example Usage of Variables and Mixins
  
  // Font Sizes
  @mixin font-size($size) {
    font-size: $size;
  }
  
  // Button Styles
  @mixin button-styles($bg-color, $font-color) {
    background-color: $bg-color;
    color: $font-color;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover {
      background-color: darken($bg-color, 10%);
    }
  }
  
  // Responsive Font Sizes
  @mixin responsive-font-size($small, $medium, $large) {
    font-size: $small;
    @include respond-to('medium') {
      font-size: $medium;
    }
    @include respond-to('large') {
      font-size: $large;
    }
  }