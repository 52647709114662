// components/footer.scss

@import '../../styles/variables';
@import '../../styles/mixins';

.footer {
  background-color: $cream-color;
  padding: 2rem 0;

  h5 {
    margin-bottom: 1rem;
    color: $dark-purple;
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 0.5rem;

      a {
        color: $dark-purple;
        text-decoration: none;
        &:hover {
          color: $dominant-purple;
          text-decoration: underline;
        }
      }
    }
  }

  .social-icons {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;

    .social-icon {
      margin-right: 1rem;
      font-size: 1.5rem;
      color: $dark-purple;

      &:hover {
        color: $dominant-purple;
      }
    }
  }

  .payment-icons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .payment-icon {
      margin: 0 0.5rem;
      font-size: 2rem;
      color: $dark-purple;

      &:hover {
        color: $dominant-purple;
      }
    }
  }

  .footer-bottom {
    margin-top: 2rem;
    text-align: center;
    color: $dark-purple;
    font-size: 0.875rem;
  }

  .footer-sections {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $light-gray;
    padding-top: 1rem;
    margin-top: 1rem;

    .section {
      flex: 1;
      padding: 0 1rem;
      &:not(:last-child) {
        border-right: 1px solid $light-gray;
      }
    }

    @include respond-to('small') {
      flex-direction: column;
      .section {
        border-right: none;
        border-bottom: 1px solid $light-gray;
        padding: 1rem 0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}