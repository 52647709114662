@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.related-products {
  margin-top: 40px;
  text-align: center;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: $dark-purple;
    font-family: $font-primary;
  }

  .carousel-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;

    button {
      background-color: $dominant-purple;
      color: white;
      border: none;
      padding: 8px 12px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }
  }

  .related-products-carousel {
    display: flex;
    overflow-x: auto; // Enable horizontal scrolling
    scroll-behavior: smooth; // Smooth scrolling for better UX
    gap: 20px;
    padding: 10px;

    &::-webkit-scrollbar {
      display: none; // Hide scrollbar for a cleaner look
    }
  }

  .related-product-item {
    flex: 0 0 200px; // Fixed width for items
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      border-radius: 4px 4px 0 0; // Rounding at the top
    }

    h3 {
      font-size: 18px;
      margin: 10px 0;
      color: $dark-purple;
      font-family: $font-secondary;
    }

    p {
      font-size: 16px;
      color: #333;
    }

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      transform: translateY(-2px); // Subtle hover effect
      transition: all 0.3s ease;
    }
  }
}