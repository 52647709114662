// Color Palette
$light-purple: #745275;
$dark-purple: #5d445f;
$cream-color: #e3d6b2;
$dominant-purple: #735b73;
$soft-yellow: #F4E3AF;
$slate-gray: #708090;
$rich-gold: #A88905;
$teal: #006D77;
$deep-blue: #003B73;
$charcoal: #36454F;
$olive-green: #808000;
$copper: #B87333;
$light-gray: #d3d3d3;
$very-light-gray: #f8f8f8;

// Fonts
$font-primary: 'Georgia', serif;
$font-secondary: 'Arial', sans-serif;
$font-action: 'Gotham', sans-serif;
$font-paragraph: 'Helvetica Neue', sans-serif;
$font-marketing: 'Neutra Display', sans-serif;
$font-decorative: 'Goudy Trajan', serif;
$font-navigation: 'Avenir', sans-serif;
$font-data: 'Helios', sans-serif;
$font-forms: 'Lucida Grande', sans-serif;
$font-special: 'Zona', sans-serif;

// Spacing
$spacing-unit: 1rem;
$spacing-unit-small: 0.5rem; // Add this line