// src/pages/LoginComponent.scss
@import '../styles/variables';
@import '../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: $cream-color;

  .login-card {
    background-color: $soft-yellow;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;

    h2 {
      font-family: $font-primary;
      color: $dark-purple;
      margin-bottom: 1rem;
    }

    .form-group {
      margin-bottom: 1.5rem;

      label {
        font-family: $font-secondary;
        color: $dark-purple;
        margin-bottom: 0.5rem;
      }

      .form-control {
        border: 1px solid $light-gray;
        border-radius: 0.25rem;
        padding: 0.75rem;
        &:focus {
          border-color: $dominant-purple;
        }
      }
    }

    .btn {
      background-color: $dominant-purple;
      color: white;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 0.25rem;
      font-family: $font-action;
      cursor: pointer;
      width: 100%;
      margin-bottom: 1rem;
      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }

    .btn-google {
      background-color: #db4437;
      &:hover {
        background-color: darken(#db4437, 10%);
      }
    }

    .btn-facebook {
      background-color: #3b5998;
      &:hover {
        background-color: darken(#3b5998, 10%);
      }
    }

    .links {
      margin-top: 1rem;
      font-family: $font-secondary;
      font-size: 0.9rem;

      a {
        color: $light-purple;
        &:hover {
          color: $dominant-purple;
          text-decoration: underline;
        }
      }
    }

    .alert {
      margin-top: 1rem;
      font-family: $font-secondary;
      padding: 0.75rem;
      border-radius: 0.25rem;
      text-align: left;
    }

    .alert-success {
      background-color: lighten($dominant-purple, 40%);
      color: $dark-purple;
    }

    .alert-danger {
      background-color: lighten(#db4437, 40%);
      color: $dark-purple;
    }
  }
}