@import '../styles/_variables';
@import '../styles/_mixins';
@import '~bootstrap/scss/bootstrap';

.product-catalogue {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  padding: $spacing-unit; /* Add padding for cleanliness */
  background-color: white; /* Use white */

  .row {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the row content */
  }
}

.product-catalogue-main {
  flex: 1;
  padding: $spacing-unit;
  padding-top: 2rem; /* Add top padding for gap */
  padding-bottom: 2rem; /* Add bottom padding for gap */
  background-color: white; /* Ensure background color is set here */

  .sorting-options {
    margin-bottom: $spacing-unit;
    select {
      padding: 0.5rem;
      border: 1px solid $light-gray;
      border-radius: 0.25rem;
      @include transition;
    }
  }

  .product-list {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: center; /* Center the products */
    .row {
      margin-top: $spacing-unit;
      width: 100%; /* Ensure the row takes full width */
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: $spacing-unit;

  .page-link {
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
    background-color: $dominant-purple;
    color: white;
    border-radius: 0.25rem;
    cursor: pointer;
    @include transition(background-color);

    &:hover {
      background-color: darken($dominant-purple, 10%);
    }
  }
}