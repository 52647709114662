@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.product-card {
  border: 1px solid $light-gray;
  padding: $spacing-unit;
  text-align: center;
  background-color: #fff;
  border-radius: 0.25rem;
  transition: transform 0.2s;
  @include box-shadow(1);

  &:hover {
    transform: scale(1.05);
    @include box-shadow(2);
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: $cream-color;

    .btn-link {
      color: $dominant-purple;
      &:hover {
        color: darken($dominant-purple, 10%);
      }
    }
  }

  .card-img-top {
    max-width: 100%;
    height: auto;
    margin-bottom: $spacing-unit;
  }

  .card-body {
    h5.card-title {
      font-size: 1.2em;
      margin: 0.5rem 0;
      font-family: $font-primary;
      color: $dark-purple;
    }

    .card-text {
      font-size: 1em;
      color: $slate-gray;
      margin-bottom: 0.5rem;
    }

    .reviews {
      font-size: 0.9em;
      color: $light-purple;
      .star-icon {
        color: $rich-gold;
        margin-right: 0.2rem;
      }
    }

    .btn-primary {
      background-color: $dominant-purple;
      border: none;
      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }
  }

  .eco-friendly {
    display: inline-block;
    margin-top: 0.5rem;
    padding: 0.25rem 0.5rem;
    background-color: $teal;
    color: white;
    border-radius: 0.25rem;
  }

  @include respond-to('small') {
    padding: $spacing-unit-small;
    .card-header, .card-body, .card-img-top {
      padding: $spacing-unit-small;
    }
  }
}