@import '../../styles/_variables';
@import '../../styles/_mixins';
@import '~bootstrap/scss/bootstrap';

.return-form {
  background-color: $cream-color;
  padding: $spacing-unit * 2;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: $spacing-unit * 2;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  .card {
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .card-header {
      background-color: $dominant-purple;
      color: white;
      font-family: $font-primary;
      text-align: center;
      padding: $spacing-unit;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    .card-body {
      padding: $spacing-unit * 2;

      label {
        font-family: $font-paragraph;
        color: $dark-purple;
        margin-bottom: $spacing-unit / 2;
        display: block;
      }

      select,
      textarea {
        width: 100%;
        padding: $spacing-unit / 2;
        border: 1px solid $light-gray;
        border-radius: 0.25rem;
        margin-bottom: $spacing-unit;
        font-family: $font-forms;

        &:focus {
          border-color: $light-purple;
          outline: none;
        }
      }

      .alert {
        margin-top: $spacing-unit;
        font-family: $font-paragraph;
      }

      .btn {
        @include button-styles($dominant-purple, white);
        width: 100%;
        margin-top: $spacing-unit;

        &:hover {
          background-color: darken($dominant-purple, 10%);
        }
      }
    }
  }
}