/* src/pages/MembershipPage.css */
.membership-page {
    padding: 2rem 0;
}

.membership-card {
    background-color: #e3d6b2 !important; /* Cream background */
    border: 1px solid #735b73 !important; /* Dominant Purple border */
    border-radius: 10px !important;
    padding: 1rem !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.membership-card .card-title {
    font-family: 'Georgia', serif;
    color: #5d445f !important; /* Dark Purple */
}

.membership-card .card-text {
    font-family: 'Helvetica Neue', sans-serif;
    color: #735b73 !important; /* Dominant Purple */
}

.membership-card .btn {
    background-color: #735b73 !important; /* Dominant Purple */
    border: none !important;
}

.membership-card .btn:hover {
    background-color: #5d445f !important; /* Dark Purple */
}

.user-membership {
    background-color: #e3d6b2 !important; /* Cream background */
    border: 1px solid #735b73 !important; /* Dominant Purple border */
    border-radius: 10px !important;
    padding: 2rem !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.user-membership h2 {
    font-family: 'Georgia', serif;
    color: #5d445f !important; /* Dark Purple */
}

.user-membership p {
    font-family: 'Helvetica Neue', sans-serif;
    color: #735b73 !important; /* Dominant Purple */
}
