@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing-unit;

  button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border: 1px solid $light-gray;
    background-color: $dominant-purple;
    color: white;
    font-family: $font-action;
    border-radius: 0.25rem;
    cursor: pointer;
    @include transition(background-color);

    &:hover {
      background-color: darken($dominant-purple, 10%);
    }

    &:disabled {
      background-color: $light-gray;
      cursor: not-allowed;
    }
  }

  span {
    font-family: $font-secondary;
    color: $dark-purple;
  }

  @include respond-to('small') {
    flex-direction: column;
    button {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
}