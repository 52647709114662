@import '../../styles/_variables';
@import '../../styles/_mixins';
@import '~bootstrap/scss/bootstrap';


.order-confirmation {
  @include container;
  background-color: white;
  padding: $spacing-unit * 2;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  h1, h2 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit;
    text-align: center;
  }

  p {
    font-family: $font-paragraph;
    color: $dark-purple;
    margin-bottom: $spacing-unit-small;
    text-align: center;
  }

  .order-summary {
    margin-top: $spacing-unit;

    p {
      margin-bottom: $spacing-unit-small;
    }
  }

  .finish-button {
    @include button-styles($dominant-purple, white);
    width: 100%;
    margin-top: $spacing-unit;
  }
}