@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.spotlight-section {
  margin-top: $spacing-unit * 4;
  padding: $spacing-unit * 2;
  background-color: $dominant-purple;
  color: $cream-color;
  border-radius: 0.25rem;
  text-align: center;
  @include box-shadow(2);

  h2 {
    font-family: $font-special;
    font-size: 1.8rem;
    margin-bottom: $spacing-unit * 2;
  }

  .content-card {
    margin-top: $spacing-unit;
    @include box-shadow(1);
    background-color: white;
    border-radius: 0.25rem;
  }
}
