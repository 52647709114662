@import '../styles/variables';
@import '../styles/mixins';
@import '~bootstrap/scss/bootstrap';

.content-detail {
  background-color: $white;
  padding: $spacing-unit * 3;

  .title {
    font-family: $font-primary;
    color: $charcoal;
    @include responsive-font-size(2rem, 2.5rem, 3rem);
    margin-bottom: $spacing-unit * 3;
  }

  .body-content {
    font-family: $font-paragraph;
    color: $dark-purple;
    line-height: 1.6;
    margin-bottom: $spacing-unit * 4;

    p {
      margin-bottom: $spacing-unit;
    }

    img {
      max-width: 100%;
      height: auto;
      margin: $spacing-unit 0;
      @include box-shadow(1);
    }

    iframe {
      width: 100%;
      max-width: 100%;
      height: 400px;
      margin-top: $spacing-unit * 2;
      border-radius: 0.25rem;
      @include box-shadow(2);
    }
  }

  .video-container {
    margin-bottom: $spacing-unit * 3;
  }

  .btn-secondary {
    background-color: $dominant-purple;
    border-color: $dominant-purple;
    color: white;
    @include button-styles($dominant-purple, $white);
    margin-bottom: $spacing-unit * 2;
  }

  .comments-section, .ratings-section {
    margin-top: $spacing-unit * 3;
    background-color: $very-light-gray;
    border-radius: 0.25rem;
    @include box-shadow(1);

    .card-title {
      font-family: $font-secondary;
      color: $dark-purple;
      font-size: 1.25rem;
      margin-bottom: $spacing-unit;
    }

    .card-text {
      font-family: $font-paragraph;
      color: $slate-gray;
      display: flex;
      align-items: center;
      gap: $spacing-unit-small;
      
      .fa-thumbs-up, .fa-star {
        color: $rich-gold;
      }
    }
  }

  // Responsive adjustments
  @include respond-to('small') {
    .title {
      font-size: 1.8rem;
    }

    .iframe {
      height: 250px;
    }
  }

  @include respond-to('medium') {
    .title {
      font-size: 2.2rem;
    }
  }

  @include respond-to('large') {
    .title {
      font-size: 2.8rem;
    }
  }
}
